<template>
    <div class="mobile_Report_list">
        <div class="page-header">
            <div class="goback flex-align-center" @click="goback">
                <img src="../../assets/newReport/goback.png" alt="">
            </div>
            <div class="title">{{ $route.query.name }}的测评报告</div>
            <!-- <div class="goback"></div> -->
        </div>
        <div class="mian_box">
            <div class="selbox">
                <div class="all_semester flex-align-center" :class="{ 'all_semester1': all_semester_active }"
                    @click="all_semester">全部</div>
                <div class="select_semester flex-align-center">
                    <el-select class="custom-select" v-model="termValue" placeholder="请选择" :popper-append-to-body="false"
                        @change="termChange" @focus="handleSelectFocus">
                        <el-option class="custom-option" v-for="(item, index) in termList" :key="index"
                            :label="item.termName" :value="item.key">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="testList">
                <ul>
                    <li class="" v-for="(item, index) in UserhistoryList_container" :key="index" @click="godetailsDetails(item)">
                        <div class="EvaluationTitle wes-2">{{ item.shortName }}</div>
                        <div class="icoRight"><i class="el-icon-arrow-right"></i></div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: {},
    data() {
        return {
            all_semester_active: false,
            termList: [],
            termValue: null,
            currentTerm: null,
            UserhistoryList_container: [],

        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        goback() {
            this.$router.go(-1);
        },
        all_semester() {
            this.all_semester_active = true;
            this.getUserhistory();
            // this.termid = '';
        },
        handleSelectFocus() {
            this.all_semester_active = false;
        },
        async getTermList() {
            let resData = await this.$Api.DataScreen.queryTermList();
            this.termList = resData.data;
            this.termValue = this.termList[0].key;
            this.currentTerm = this.termList[0].termName;
            this.getUserhistory();
        },
        async getUserhistory() {
            let data = {
                userId: this.$route.query.id,
                termKey: this.all_semester_active ? '' : this.termValue,
            };
            let resData = await this.$Api.Myclass.getUserhistory(data);
            this.UserhistoryList_container = resData.data;
            // console.log('历史记录', resData);
        },
        termChange(val) {
            const index = this.termList.findIndex(item => item.key == val);
            this.currentTerm = this.termList[index].termName;
            this.getUserhistory();

        },
        async TestResultsViewLog(value) {
            let data = {
                userId: this.$route.query.id,
                examId: value.testId,
            }
            let resData = await this.$Api.Myclass.TestResultsViewLog(data);
            if (resData.data) {
                this.$router.push({
                    path: '/NewReportZhuShouStuMove',
                    query: {
                        userId: this.$route.query.id,
                        termKey: this.termValue,
                        termKeyName: this.currentTerm,
                        reportValue: value.examType,
                        monthValue: value.testId,
                        name: this.$route.query.name
                    }
                });
            }
        },
        godetailsDetails(data) {
            this.TestResultsViewLog(data)

        }
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.getTermList()

    },
}
</script>
<style lang="less" scoped>
@import url('./less/report_stu_zhushou_new_list.less');
</style>